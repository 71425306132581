<script lang="ts" setup>
  import RouletteSlider from '~/components/sliders/roulette/RouletteSlider.vue';
  import SkeletonGenres from '~/pages/browse/components/SkeletonGenres.vue';
  import GenreCard from '~/components/cards/GenreCard.vue';
  import { getGenres } from '~/services/modules/movies.service';
  import { maxGenreCardsCountAtSlider } from '~/consts/slider';

  const { data, suspense, isFetching } = getGenres();
  const { homeGenreInitialIndex } = storeToRefs(useSliderStore());
  const { checkIsNavigatedBackOrFront } = useSliderStore();

  if (!checkIsNavigatedBackOrFront()) {
    homeGenreInitialIndex.value = 0;
  }

  onServerPrefetch(async () => await suspense());

  const beforeSliderDestroy = swiper => {
    homeGenreInitialIndex.value = swiper.activeIndex;
  };
</script>

<template>
  <v-wrapper
    section
    class="section-genres full-width"
    removePadding
    title="Жанры"
    :link="{
      name: 'В каталог',
      mobileName: 'В каталог',
      route: { name: 'browse-category', params: { category: 'popular' } },
    }"
  >
    <roulette-slider
      appendClass="continue__slider content-right"
      :items="data?.slice(0, maxGenreCardsCountAtSlider) ?? []"
      :is-fetching="isFetching"
      :initial-slide-index="homeGenreInitialIndex"
      skeleton
      @before-destroy="beforeSliderDestroy"
    >
      <template #default="{ movie }">
        <genre-card :data="movie" />
      </template>
      <template #skeleton>
        <skeleton-genres />
      </template>
    </roulette-slider>
  </v-wrapper>
</template>

<style lang="scss" scoped>
  .section-genres {
    position: relative;
    margin-bottom: 76px;

    &.roulette-slider__control {
      height: 100%;
    }

    @include gradientBackground(-400px);
    @media (max-width: $retina) {
      margin-bottom: 60px;
      //padding: 24px 0;
    }

    .continue__slider {
      &:deep(.roulette-slider__control) {
        height: 102.5%;
      }
    }
  }
</style>
